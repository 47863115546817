<template>
  <v-form @submit.prevent="saveForm" ref="form">
    <BreadcrumbsField :items="itemsBreadcrumbs"/>
    <CustomView
      v-if="isProductFetched"
      view-name="product_detail"
      :value="currentItem"
      @input="updateItem($event)"
      :options="options"
      :custom-component-catalog="customComponentCatalog"
    />
    <v-row class="mx-2">
      <v-btn
        color="primary"
        :loading="loadingSave"
        :disabled="loadingSave"
        @click="loader = 'loadingSave'">
        <v-icon left dark>fa-save</v-icon>
        Guardar
      </v-btn>
      <v-btn
        class="mx-2"
        color="primary"
        :loading="loadingSaveAndContinue"
        :disabled="loadingSaveAndContinue"
        @click="loader = 'loadingSaveAndContinue'">
        <v-icon left dark>fa-save</v-icon>
        Guardar y continuar editando
      </v-btn>
    </v-row>
    <PrettyAlert :text="textAlert" v-model="showAlert"/>
  </v-form>
</template>

<script>
import PrettyAlert from '@/lib/unlogin/components/PrettyAlert'
import CustomView from '@/lib/uncustomui/components/CustomView'
import BreadcrumbsField from '@/apps/core/components/forms/BreadcrumbsField'
import ProductSubstitution from '@/apps/core/components/ProductSubstitution/ProductSubstitution'
import ProductEquivalence from '@/apps/core/components/ProductEquivalence'
import { BASE_URL, RESPONSE_LEVEL } from '@/variables'
import { mapState, mapActions } from 'vuex'
import {
  createErrorNotification,
  createSuccessNotification,
  createWarningNotification
} from '@/lib/unnotificationsqueue'

export default {
  name: 'ProductForm',
  components: { PrettyAlert, CustomView, BreadcrumbsField },
  data () {
    return {
      itemsBreadcrumbs: [
        {
          text: 'LISTA DE PRODUCTOS',
          disabled: false,
          name: 'JunoProductList'
        },
        {
          text: 'PRODUCTO',
          disabled: true
        }
      ],
      loader: null,
      loadingSave: false,
      loadingSaveAndContinue: false,
      options: {
        context: {
          serverUrl: BASE_URL
        }
      },
      productId: this.$route.params.id,
      textAlert: '',
      showAlert: false,
      isProductFetched: null,
      customComponentCatalog: {
        ProductSubstitution: ProductSubstitution,
        ProductEquivalence: ProductEquivalence
      }
    }
  },
  async created () {
    if (this.productId > 0) {
      try {
        await this.getItem(this.productId)
        this.isProductFetched = true
      } catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    ...mapState('products', ['currentItem'])
  },
  methods: {
    ...mapActions('products', ['getItem', 'patchItem', 'updateCurrentItem']),
    ...mapActions({ addNotification: 'addNotification' }),
    saveForm (redirect) {
      this.patchItem(this.productId)
        .then(response => {
          if (redirect) {
            this.$router.push({ name: 'JunoProductList' })
          } else {
            this.displayNotification(response)
            this.stopLoadingButton()
          }
        })
        .catch(error => {
          this.displayNotification(error)
          this.stopLoadingButton()
        })
    },
    displayNotification (message) {
      if (message.level === RESPONSE_LEVEL.SUCCESS) {
        this.addNotification(createSuccessNotification(message.message))
      } else if (message.level === RESPONSE_LEVEL.WARNING) {
        this.addNotification(createWarningNotification(message.message))
      } else if (message.level === RESPONSE_LEVEL.ERROR) {
        this.addNotification(createErrorNotification(message.message))
      } else {
        this.addNotification(createErrorNotification('Al parecer algo salió mal ☹️'))
      }
    },
    stopLoadingButton () {
      this.loadingSave = false
      this.loadingSaveAndContinue = false
    },
    updateItem (items) {
      this.updateCurrentItem({ ...this.currentItem, ...items })
    }
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      if (l === 'loadingSave') {
        this.saveForm(true)
      } else if (l === 'loadingSaveAndContinue') {
        this.saveForm(false)
      }

      this.loader = null
    }
  }
}
</script>

<style scoped>

</style>
