<template>
  <Layout :top-bar-title="title" :sections="sections">
    <ProductForm/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import sections from './navigationDrawerSections'
import ProductForm from '../components/forms/ProductForm'

export default {
  name: 'ProductDetail',
  components: { Layout, ProductForm },
  data () {
    return {
      sections: sections,
      title: this.$t('products.product')
    }
  }
}
</script>

<style scoped>

</style>
