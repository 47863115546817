<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn @click="toggleDialog" color="primary" v-text="'Establecer'" />
    </div>

    <v-dialog v-model="showDialog" width="600px">
        <v-card :loading="loading" :disabled="loading">
            <v-card-title v-text="product.name" />

            <v-card-text class="d-flex flex-column">
              <v-autocomplete
                v-model="selectedProduct"
                label="Seleccionar producto"
                :items="productList"
                :loading="listLoading"
                return-object
              />
              <div class="product-count">
                <v-progress-linear v-if="countLoading" class="text-linear-loading mt-1" buffer-value="0" stream indeterminate />
                <span v-else-if="productCount === 'error'" class="red--text" v-text="'No se han podido determinar los acuerdos afectados'" />
                <span v-else-if="productCount !== null" v-text="`${productCount} acuerdos afectados`" />
              </div>
            </v-card-text>

          <v-card-actions class="d-flex justify-end pb-5 pr-5">
            <v-btn @click="cancel" class="px-4" v-text="'Cancelar'" />
            <v-btn @click="apply" color="primary" class="ml-4 px-4" :disabled="disableApplyBtn" v-text="'Aplicar'" />
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiClient from '@/lib/unlogin/store/apiclient'
import { createErrorNotification, createSuccessNotification } from '@/lib/unnotificationsqueue'
import { mapActions } from 'vuex'

export default {
  name: 'ProductEquivalence',
  props: {
    value: Object
  },
  data () {
    return {
      showDialog: false,
      productList: [],
      selectedProduct: null,
      productCount: null,
      loading: false,
      listLoading: false,
      countLoading: false
    }
  },
  computed: {
    product () {
      return this.value
    },
    disableApplyBtn () {
      return this.productCount === null || this.productCount === 'error' || this.selectedProduct === null || this.productList.length === 0
    }
  },
  watch: {
    selectedProduct () {
      if (this.selectedProduct) this.getAgreementsAffectedCount()
    }
  },
  methods: {
    ...mapActions({ addNotification: 'addNotification' }),
    async apply () {
      try {
        this.loading = true
        const body = {
          origin_product: this.value.id,
          equivalent_product: this.selectedProduct.value
        }
        await apiClient.post('/agreements/add-equivalent-product-to-agreements', body)
        this.addNotification(createSuccessNotification('Cambios ejecutándose en segundo plano, estarán disponibles en unos minutos'))
        this.cancel()
      } catch (e) {
        console.log(e)
        this.addNotification(createErrorNotification(`Fallo al aplicar equivalencia al producto "${this.product.name}"`))
      } finally {
        this.loading = false
      }
    },
    async getAgreementsAffectedCount () {
      try {
        this.countLoading = true
        const originProductID = this.value.id
        const equivalentProductID = this.selectedProduct.value
        const response = await apiClient.get(`/agreements/add-equivalent-product-to-agreements?origin_product=${originProductID}&equivalent_product=${equivalentProductID}`)
        this.productCount = response.data.count
      } catch (e) {
        console.log(e)
        this.productCount = 'error'
        this.addNotification(createErrorNotification('Fallo al obtener el número de acuerdos'))
      } finally {
        this.countLoading = false
      }
    },
    async getProductList () {
      try {
        this.listLoading = true
        const response = await apiClient.get('/active-products-choices/')
        this.productList = response.data.map(product => ({
          sku: product.sku,
          text: `${product.sku} - ${product.text}`,
          value: product.value
        }))
      } catch (e) {
        console.log(e)
        this.addNotification(createErrorNotification('Fallo al obtener la lista de productos'))
      } finally {
        this.listLoading = false
      }
    },
    cancel () {
      this.productCount = null
      this.selectedProduct = null
      this.toggleDialog()
    },
    toggleDialog () {
      this.showDialog = !this.showDialog
      if (this.productList.length === 0) this.getProductList()
    }
  }
}
</script>

<style lang="scss" scoped>
.text-linear-loading {
  width: 33%;
}

.product-count {
  height: 16px;
}
</style>
